import React from 'react'
import { graphql, Link } from 'gatsby'
import { Text, View, TouchableOpacity, StyleSheet } from 'react-native'
import { Trans, withI18n, withI18nProps } from '@lingui/react'
import SearchInput, { createFilter } from 'react-native-search-filter'
import { P } from '@src/components/atoms/P'
import { i18n } from '@lingui/core'

// interface StyleSheet {
//   [key: string]: StyleSheet
// }
const styles = StyleSheet.create({
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
  },
  text: {
    paddingBottom: 100,
  },
  pageSubject: {},
  pageItem: {},
  searchInput: {},
})

const Li = (props: any) => (
  <li>
    <Link to={props.path}>{props.path}</Link>
  </li>
)
const List = (props: any) => (
  <ul>
    {props.edges.map(({ node }: any) => (
      <Li key={node.path} {...node} />
    ))}
  </ul>
)

const KEYS_TO_FILTERS = [
  'context.title',
  'context.description',
  'context.keywords',
]

interface NotFoundPageProps extends withI18nProps {
  data: {
    allSitePage: {
      edges: {
        node: {
          id: string
          path: string
          context: {
            basePath: string
            title: string
            description: string
            keywords: string
            language: string
          }
        }
      }[]
    }
  }
}
interface NotFoundPageState {
  searchTerm: string
}
@(withI18n() as any)
export default class NotFoundPage extends React.Component<
  NotFoundPageProps,
  NotFoundPageState
> {
  constructor(props: NotFoundPageProps) {
    super(props)
    this.state = {
      searchTerm: '',
    }
  }

  // componentDidMount() {
  //   loadData
  // }

  searchUpdated(term: string) {
    this.setState({ searchTerm: term })
  }

  render() {
    const { data } = this.props
    const pages = data.allSitePage.edges.map(({ node }) => node)
    const filteredPages = pages.filter(
      createFilter(this.state.searchTerm, KEYS_TO_FILTERS, { fuzzy: true }),
    )
    return (
      <View style={styles.box}>
        <h1
          style={{
            padding: 100,
          }}
        >
          <Trans>NOT FOUND</Trans>
        </h1>
        <P style={styles.text}>
          <Trans>
            You just hit a route that doesn&#39;t exist... Please check URL
            again.
          </Trans>
        </P>
        <View>
          <SearchInput
            onChangeText={(term) => {
              this.searchUpdated(term)
            }}
            style={styles.searchInput}
            placeholder={i18n.t`Search pages...`}
          />
          <View>
            {filteredPages.map((page) => (
              <TouchableOpacity
                onPress={() => alert(page.context.title)}
                key={page.id}
                style={styles.pageItem}
              >
                <View>
                  <Text>{page.context.title}</Text>
                  <Text style={styles.pageSubject}>
                    {page.context.description}
                  </Text>
                </View>
              </TouchableOpacity>
            ))}
          </View>
        </View>
        <View>
          <Text>
            <Trans>All Url are here</Trans>
          </Text>
        </View>
        <List {...data.allSitePage} />
      </View>
    )
  }
}

export const pageQuery = graphql`
  query Error404Query($language: String) {
    allSitePage(
      filter: {
        path: {
          regex: "/^(?!/(ja/|)(dev-404-page|404|offline-plugin-app-shell-fallback|__|.*thanks|legal/privacy|legal/terms)).*$/"
        }
      }
      sort: { fields: [path] }
    ) {
      edges {
        node {
          id
          path
          # jsonName
          # component
          # internal {
          #   type
          #   contentDigest
          #   description
          #   owner
          # }
          context {
            basePath
            title
            description
            keywords
            language
          }
        }
      }
    }
    ...FooterFragment
  }
`
